import React from 'react';
import ReactDOM from 'react-dom/client';
import { Button, Grid, Card, extendTheme, CssVarsProvider, Input, FormControl, InputLabel } from '@mui/joy';
import App from './App';
import reportWebVitals from './reportWebVitals';

const theme = extendTheme({ cssVarPrefix: 'demo' });
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
            <CssVarsProvider defaultMode="dark" theme={theme} colorSchemeSelector="#demo_dark-mode-by-default" modeStorageKey="demo_dark-mode-by-default" disableNestedContext>

    <App />
    </CssVarsProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
