import React, { useEffect, useState } from 'react';
import { useTable } from 'react-table';
import { Grid, Input, Sheet, Table } from "@mui/joy/"
import Config from '../Config';

function formatNumber(num) {
    const sign = num < 0 ? '-' : '';
    const absNum = Math.abs(num);

    if (absNum < 1000) return sign + absNum; //Stovky
    if (absNum < 1000000) return sign + Math.round(absNum / 1000) + 'k'; // Tisíce

    const formatWithOptionalDecimal = (value, unit) => {
        const number = Math.floor(value) === value ? Math.floor(value) : value.toFixed(1);
        return sign + number + unit;
    };

    if (absNum < 1000000000) return formatWithOptionalDecimal(absNum / 1000000, 'M'); // Miliony
    if (absNum < 1000000000000) return formatWithOptionalDecimal(absNum / 1000000000, 'B'); // Miliardy
    return formatWithOptionalDecimal(absNum / 1000000000000, 'T'); // Triliony
}

function formatDate(isoString) {
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Měsíce jsou od 0 do 11
    const year = date.getFullYear();
    return `${day}. ${month}. ${year}`;
}


const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData,
}) => {
  const isEditableColumn = id !== 'nick'; 
  const isFormattedColumn = id === 'donate' || id === 'fee' || id === 'rozdil';
  const isDateColumn = id === 'date'; 
  const formattedValue = isFormattedColumn ? formatNumber(initialValue) 
                           : isDateColumn ? formatDate(initialValue)
                           : initialValue;
  
  const [value, setValue] = useState(formattedValue);

  const onChange = e => {
    const newValue = e.target.value;
    if (isFormattedColumn || isDateColumn) {
      setValue(newValue);
    } else {
      setValue(newValue);
    }
  };
  

  const onBlur = () => {
    let updatedValue = value;
    if (isFormattedColumn && value.endsWith('k')) {
      updatedValue = parseInt(value.slice(0, -1)) * 1000;
    } else if (isDateColumn) {
      // Převod DD. MM. RRRR na formát ISO
      const parts = value.split('.').map(part => parseInt(part.trim()));
      updatedValue = new Date(parts[2], parts[1] - 1, parts[0]).toISOString();
    }
    updateMyData(index, id, id === 'discordId' ? updatedValue : updatedValue.toString());
  };

  if (!isEditableColumn) {
    return <Input disabled variant="soft" size="sm" value={value} onChange={onChange} onBlur={onBlur} style={{color: "white"}}/>;
  }

  return <Input variant="soft" size="sm" value={value} onChange={onChange} onBlur={onBlur} style={{backgroundColor: "#16191d"}}/>;
};

const EditTable = () => {
  const [data, setData] = useState([]);

  const fetchData = () => {
    fetch(`${Config.domain}/api/members`)
        .then(response => response.json())
        .then(response => {
            const formattedData = response.map(item => ({
              ...item,
              rozdil: item.donate - item.fee,
            })).sort((a, b) => b.donate - a.donate); // Přidán řádek pro seřazení
            setData(formattedData);
          })
        .catch(error => console.error('Error:', error));
}


useEffect(() => {
    fetchData();
}, []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nick',
        accessor: 'nick',
        Cell: EditableCell,
      },
      {
        Header: 'Discord ID',
        accessor: 'discordId',
        Cell: EditableCell,
      },
      {
        Header: 'Roblox ID',
        accessor: 'robloxId',
        Cell: EditableCell,
      },
      {
        Header: 'Přijat',
        accessor: 'date',
        Cell: EditableCell,
      },
      {
        Header: 'Přispěl',
        accessor: 'donate',
        Cell: EditableCell,
      },
      {
        Header: 'Poplatek',
        accessor: 'fee',
        Cell: EditableCell,
      },
      {
        Header: 'Discord',
        accessor: 'discord',
        Cell: EditableCell,
      },
    ],
    []
  );

  const updateMyData = (rowIndex, columnId, value) => {
    const updatedRow = { ...data[rowIndex], [columnId]: value };
    if (columnId === 'donate' || columnId === 'fee') {
      updatedRow.rozdil = updatedRow.donate - updatedRow.fee;
    }
    const newData = [...data];
    newData[rowIndex] = updatedRow;
    setData(newData);


    const token = localStorage.getItem('token');
    fetch(`${Config.domain}/api/members/settings`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(updatedRow)
    })
    .then(response => response.json())
    .then(data => {
      console.log('Data updated', data);
    })
    .catch(error => {
      console.error('Error updating data', error);
    });
  };
  
  const tableInstance = useTable({
    columns,
    data,
    defaultColumn: { Cell: EditableCell },
    updateMyData,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
<>
<Grid container>
        <Grid item xs={12}>
      <Sheet {...getTableProps()} style={{ width: '100%' }} sx={{
        '& thead th:nth-child(1)': { width: '20%' },
        '& thead th:nth-child(2)': { width: '20%' }
      }}>
         <Table
  borderAxis="none"
  size="sm"
  stickyHeader
>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} style={{textAlign: "left", fontSize: "0.85rem", fontWeight: 600, paddingLeft: "2px"}}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}><Grid container><Grid item xs={12}> {cell.render('Cell')}</Grid></Grid></td>;
                })}
              </tr>
            );
          })}
        </tbody>
        </Table>
      </Sheet>
      </Grid>
      </Grid>
      </>
  );
};

export default EditTable;