import logo from './logo.svg';
import './App.css';
import RegisterForm from "./components/RegisterForm"
import LoginForm from "./components/LoginForm"
import AdminPanel from "./components/AdminPanel"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import Root from "./components/Root"

function App() {
  return (
    <AdminPanel/>
  );
}

export default App;
