import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { Tabs } from '@mui/base/Tabs';
import { TabsList as BaseTabsList } from '@mui/base/TabsList';
import { TabPanel as BaseTabPanel } from '@mui/base/TabPanel';
import { buttonClasses } from '@mui/base/Button';
import { Tab as BaseTab, tabClasses } from '@mui/base/Tab';
import { Grid, LinearProgress } from '@mui/joy';
import MyTable from "./MyTable"
import ClanProfile from "./ClanProfile"
import ApplicationsList from "./ApplicationsList"
import AdminSettings from "./AdminSettings"
import EditTable from "./EditTable"
import PrivateServer from "./PrivateServer"
import Config from '../Config';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import AssignmentIndRoundedIcon from '@mui/icons-material/AssignmentIndRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import SportsEsportsRoundedIcon from '@mui/icons-material/SportsEsportsRounded';

export default function AdminPanel() {
  const [userRole, setUserRole] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchRole = async () => {
      setIsLoading(true);
      const token = localStorage.getItem('token');
      if (!token) {
        console.log('No token found');
        setUserRole(undefined); // Explicitně nastav undefined pro indikaci nepřihlášeného uživatele
        setIsLoading(false);
        return;
      }
    
      try {
        const response = await fetch(`${Config.domain}/api/role`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
    
        if (!response.ok) {
          throw new Error('Invalid token');
        }
    
        const data = await response.json();
        console.log(data.role);
        setUserRole(data.role);
      } catch (error) {
        console.error(error);
        localStorage.removeItem('token'); // Odebrání tokenu při chybě
        setUserRole(undefined); // I v případě chyby nastav role na undefined
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchRole();
  }, []);
  
  const isTabDisabled = (tabIndex) => {
    if (tabIndex === 5) {
      return userRole === 'pending' || userRole === undefined || userRole === null;
    }
    if (userRole === 'pending' || userRole === 'member' || userRole === undefined || userRole === null) {
      return tabIndex > 1;
    }
    return false;
  };

  if (isLoading) {
    return <LinearProgress variant="soft" fullWidth/>
  }

  const ResponsiveDiv = styled('div')(({ theme }) => ({
    marginLeft: '15%',
    marginRight: '15%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '1%',
      marginRight: '1%',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0%',
      marginRight: '0%',
    }
  }));

  
  return (
    <ResponsiveDiv>
    <Grid container justifyContent="center" alignItems="center" style={{ width: '100%' }}>
    <Grid item xs={12} sm={12} md={12}>
    <Tabs defaultValue={0}>
      <TabsList>
          <Tab value={0} disabled={isTabDisabled(0)}>
            <span className="text">Můj clan</span>
            <HomeRoundedIcon className="icon"/>
          </Tab>
          <Tab value={1} disabled={isTabDisabled(1)}>
            <span className="text">Členové</span>
            <GroupsRoundedIcon className="icon"/>
          </Tab>
          <Tab value={2} disabled={isTabDisabled(2)}>
            <span className="text">Admin nastavení</span>
            <SettingsRoundedIcon className="icon"/>
          </Tab>
          <Tab value={3} disabled={isTabDisabled(3)}>
            <span className="text">Přihlášky</span>
            <AssignmentIndRoundedIcon className="icon"/>
          </Tab>
          <Tab value={4} disabled={isTabDisabled(4)}>
            <span className="text">Správa členů</span>
            <ManageAccountsRoundedIcon className="icon"/>
          </Tab>
          <Tab value={5} disabled={isTabDisabled(5)}>
            <span className="text">Private server</span>
            <SportsEsportsRoundedIcon className="icon"/>
          </Tab>
        </TabsList>
      <TabPanel value={0}><ClanProfile/></TabPanel>
      <TabPanel value={1}><MyTable/></TabPanel>
      <TabPanel value={2}><AdminSettings/></TabPanel>
      <TabPanel value={3}><ApplicationsList/></TabPanel>
      <TabPanel value={4}><EditTable/></TabPanel>
      <TabPanel value={5}><PrivateServer/></TabPanel>
    </Tabs>
    </Grid>
    </Grid>
    </ResponsiveDiv>
  );
}

const blue = {
  50: '#0B0D0E',
  100: '#0B0D0E',
  200: '#0B0D0E',
  300: '#0B0D0E',
  400: '#0B0D0E',
  500: '#0B0D0E',
  600: '#0B0D0E',
  700: '#0B0D0E',
  800: '#0B0D0E',
  900: '#0B0D0E',
};

const grey = {
  50: '#090b0e',
  100: '#090b0e',
  200: '#090b0e',
  300: '#090b0e',
  400: '#090b0e',
  500: '#090b0e',
  600: '#090b0e',
  700: '#434D5B',
  800: '#303740',
  900: '#090b0e',
};

const Tab = styled(BaseTab)`
  font-family: 'IBM Plex Sans', sans-serif;
  color: #fff;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: transparent;
  width: 100%;
  padding: 10px 12px;
  margin: 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;


  &:hover {
    background-color: ${blue[400]};
  }

  &:focus {
    color: #fff;
    outline: 3px solid ${blue[200]};
  }

  &.${tabClasses.selected} {
    background-color: #fff;
    color: ${blue[600]};
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .icon {
    display: none;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    .text {
      display: none;
    }

    .icon {
      display: block;
    }
  }
`;

const TabPanel = styled(BaseTabPanel)(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  padding: 20px 12px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  border-radius: 12px;
  opacity: 0.6;
  `,
);

const TabsList = styled(BaseTabsList)(
  ({ theme }) => `
  min-width: 100%;
  background-color: ${blue[500]};
  border-radius: 12px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  box-shadow: 0px 4px 30px ${theme.palette.mode === 'dark' ? grey[900] : grey[200]};
  `,
);