import React, { useEffect, useState } from 'react';
import { Table, Tooltip, Box, Typography, Chip, Modal, ModalDialog, Button, Alert, Sheet } from '@mui/joy/';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import CloseIcon from '@mui/icons-material/Close';
import Config from '../Config';
import "./css/table.css"

//obrázky
import autoFarmImage from '../icons/auto_farm.webp';
import autoTapImage from '../icons/auto_tap.webp';
import hugeHunterImage from '../icons/huge_hunter.webp';
import luckyImage from '../icons/lucky.webp';
import magicEggsImage from '../icons/magic_eggs.webp';
import moreEggsImage from '../icons/more_eggs.webp';
import petEquipImage from '../icons/pet_equip.webp';
import ultraLuckyImage from '../icons/ultra_lucky.webp';
import vipImage from '../icons/vip.webp';
import enchantImage from '../icons/enchant.webp';

export default function TableColumnWidth() {
    const [rows, setRows] = useState([]);
    const [totalCount, setTotalCount] = useState(0)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedNick, setSelectedNick] = useState('');
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertType, setAlertType] = useState(null);

    
      const fetchData = () => {
        fetch(`${Config.domain}/api/members`)
            .then(response => response.json())
            .then(data => {
              const sortedData = data.sort((a, b) => {
                // Owner vždy na začátek
                if (a.role === 'owner') return -1;
                if (b.role === 'owner') return 1;
              
                // Officer mezi sebou řadíme podle výše příspěvku
                if (a.role === 'officer' && b.role === 'officer') {
                  return b.clanPoints - a.clanPoints; // Sestupně podle příspěvku
                }
              
                // Officer před ostatními, pokud jeden z nich je officer a druhý ne
                if (a.role === 'officer') return -1;
                if (b.role === 'officer') return 1;
              
                // Ostatní členové seřazení podle příspěvku sestupně
                return b.clanPoints - a.clanPoints;
              });
    
            setRows(sortedData);
                setTotalCount(data.length)
            })
            .catch(error => console.error('Error:', error));
    }

    useEffect(() => {
      fetchData();
    }, []); 

    const openModal = (nick) => {
      setSelectedNick(nick);
      setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const showAlert = (message, type) => {
      setAlertMessage(message);
      setAlertType(type);
    };

    const handleDelete = () => {
      const token = localStorage.getItem('token');
      fetch(`${Config.domain}/api/member/delete/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ nick: selectedNick })
      })
        .then((response) => {
          if (response.status === 200) {
            fetchData()
            showSuccessAlert(`Uživatel ${selectedNick} byl úspěšně smazán.`);
            closeModal();
          } else if (response.status === 403) {
            showErrorAlert(`Tak tohle asi neklapne kámo, nemáš oprávnění. :)`);
            closeModal();
          } else {
            showErrorAlert(`Chyba při mazání uživatele ${selectedNick}.`);
            console.error('Chyba při mazání záznamu:', response.message);
            closeModal();
          }
        })
        .catch((error) => {
          showErrorAlert(`Chyba při mazání uživatele ${selectedNick}.`);
          console.error('Chyba při mazání záznamu:', error);
          closeModal();
        });
    };
    

    function formatNumber(num, isCWPoints = false) {
      const sign = num < 0 ? '-' : '';
      const absNum = Math.abs(num);
    
      const formatWithOptionalDecimal = (value, divider, unit) => {
        const dividedValue = value / divider;
        let formattedNumber;
        if (Math.floor(dividedValue) !== dividedValue) {
          formattedNumber = dividedValue.toFixed(2);
        } else {
          formattedNumber = dividedValue.toFixed(0);
        }
        return sign + formattedNumber + unit;
      };
    
      if (isCWPoints) {
        if (absNum < 1000) return sign + absNum.toFixed(0);
        if (absNum < 1000000) return sign + (absNum / 1000).toFixed(2) + 'k';
        if (absNum < 1000000000) return formatWithOptionalDecimal(absNum, 1000000, 'M');
        if (absNum < 1000000000000) return formatWithOptionalDecimal(absNum, 1000000000, 'B');
      } else {
        if (absNum < 1000) return sign + absNum.toFixed(0);
        if (absNum < 1000000) return sign + (absNum / 1000).toFixed(0) + 'k';
        if (absNum < 1000000000) return formatWithOptionalDecimal(absNum, 1000000, 'M');
        if (absNum < 1000000000000) return formatWithOptionalDecimal(absNum, 1000000000, 'B');
        return formatWithOptionalDecimal(absNum, 1000000000000, 'T');
      }
    
      return sign + absNum; // Pro jiné případy
    }

    function formatDate(isoString) {
        const date = new Date(isoString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Měsíce jsou od 0 do 11
        const year = date.getFullYear();
        return `${day}. ${month}. ${year}`;
    }

    function getDiffCellStyle(diff) {
        if (diff >= 1) {
            return { backgroundColor: 'rgba(217, 234, 211, 0.4)', color: '#D9EAD3', fontWeight: 600 };
        } else if (diff >= -700000) {
            return { backgroundColor: 'rgba(252, 232, 178, 0.4)', color: '#FCE8B2', fontWeight: 600 };
        } else {
            return { backgroundColor: 'rgba(244, 204, 204, 0.4)', color: '#F4CCCC', fontWeight: 600 };
        }
    }

    function getDiffCellStyleCW(diff) {
      if (diff >= 45) {
          return { backgroundColor: 'rgba(217, 234, 211, 0.4)', color: '#D9EAD3', fontWeight: 600}; // Zelená
      } else if (diff >= 30 && diff < 35) {
          return { backgroundColor: 'rgba(252, 232, 178, 0.4)', color: '#FCE8B2', fontWeight: 600 }; // Oranžová
      } else {
          return { backgroundColor: 'rgba(244, 204, 204, 0.4)', color: '#F4CCCC', fontWeight: 600 }; // Červená
      }
  }

    const renderRoleChip = (role) => {
      let color = '';
      let label = '';

      switch(role) {
        case 'owner':
          color = 'danger';
          label = 'Owner';
          break;
        case 'officer':
          color = 'warning';
          label = 'Officer';
          break;
        default:
          return null;
      }

      return <Chip color={color} variant="solid" size="sm" style={{marginLeft: "5px"}}><strong>{label}</strong></Chip>;
    };

    const renderGamePasses = (gamepasses, enchantSlots) => {
      const gamePassImages = {
        autofarm: autoFarmImage,
        autotap: autoTapImage,
        hugeHunter: hugeHunterImage,
        lucky: luckyImage,
        magicEggs: magicEggsImage,
        moreEggs: moreEggsImage,
        petEquip: petEquipImage,
        ultraLucky: ultraLuckyImage,
        vip: vipImage,
        enchant: enchantImage
      };
      const gamePassElements = Object.entries(gamepasses).map(([key, value]) => {
        if (value === true) {
          return (
            <Tooltip key={key} variant="outlined" title={key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}>
              <img src={gamePassImages[key]} alt={key} style={{ width: '25px', height: '25px', marginTop: "2px" }} />
            </Tooltip>
          );
        }
        return null;
      });
    
      if (enchantSlots > 0) {
        gamePassElements.push(
          <div key="enchant" style={{ position: 'relative' }}>
            <Tooltip key="enchant" variant="outlined" title="Enchant slots">
            <img src={gamePassImages['enchant']} alt="enchant" style={{ width: '25px', height: '25px', marginTop: "1px" }} />
            </Tooltip>
            <Typography
              component="span"
              sx={{
                position: 'absolute',
                top: '50%',
                left: '80%',
                transform: 'translate(-50%, -50%)',
                color: 'white',
                textShadow: `
                  -1px -1px 0 #000,  
                  1px -1px 0 #000,
                  -1px 1px 0 #000,
                  1px 1px 0 #000`,
                fontSize: '1rem',
                fontWeight: "800"
              }}
            >
              {enchantSlots}
            </Typography>
          </div>
        );
      }
    
      return gamePassElements;
    };

    const showErrorAlert = (message) => {
      showAlert(message, 'danger');
    };

    const showSuccessAlert = (message) => {
      showAlert(message, 'success');
    };

    const isUserLoggedIn = () => {
      return localStorage.getItem('token') !== null;
    };
          
  return (
    <>
    {isModalOpen && (
        <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <ModalDialog style={{ width: "350px", borderRadius: "12px", padding: "40px" }}>
            <Typography component="h4" style={{ fontWeight: 'bold', marginBottom: "15px" }}>Opravdu chcete smazat uživatele {selectedNick}?</Typography>
            <Box sx={{ display: 'flex', gap: 1.5, '& > button': { flex: 1 } }}>
          <Button
                color="neutral"
                variant="outlined"
                style={{borderRadius: "8px"}}
                startDecorator={<CloseIcon style={{fontSize: 20}}/>}
                onClick={closeModal}
            >
                NE
            </Button>
            <Button
                color="danger"
                style={{borderRadius: "8px"}}
                startDecorator={<PersonRemoveIcon style={{fontSize: 20}} />}
                onClick={handleDelete}
            >
                <strong>SMAZAT</strong>
            </Button>
          </Box>
          </ModalDialog>
        </Modal>
      )}
      {alertMessage && (
  <Alert
    color={alertType}
    style={{borderRadius: "7px", marginBottom: "15px"}}
  >
    <strong>{alertMessage}</strong>
  </Alert>
)}
<Sheet sx={{ overflow: 'auto'}}>
    <Table size="sm" stickyHeader hoverRow
      sx={{
        '& tbody tr:hover': {
          backgroundColor: 'rgba(255,255,255, 0.1)',
          borderRadius: "18px",
          zIndex: 200
        },
        '& tr > *:first-child': {
          position: 'sticky',
          left: 0,
          '@media (max-width:600px)': {
            bgcolor: 'background.surface',
            zIndex: 100
          },
        },
        '@media (max-width:600px)': { 
          width: 'max-content',
        },
        '& thead th:nth-child(1)': { width: '18%' },
        '& thead th:nth-child(4)': { width: '7%' },
        '& thead th:nth-child(5)': { width: '7%' },
        '& thead th:nth-child(6)': { width: '7%' },
        '& thead th:nth-child(7)': { width: '7%' },
        '& thead th:nth-child(8)': { width: '10%' },
        '& thead th:nth-child(9)': { width: '4%' },
      }}>
      <thead>
        <tr>
          <th style={{fontSize: "0.9rem", fontWeight: 600, width: 'var(--Table-firstColumnWidth)'}}>Nickname [{totalCount}/75]</th>
          <th style={{textAlign: "left", fontSize: "0.9rem", fontWeight: 600}}>GamePass</th>
          <th style={{textAlign: "right", fontSize: "0.9rem", fontWeight: 600}}>Přijat</th>
          <th style={{textAlign: "right", fontSize: "0.9rem", fontWeight: 600}}>Přispěl</th>
          <th style={{textAlign: "right", paddingRight: "15px", fontSize: "0.9rem", fontWeight: 600}}>Poplatek</th>
          <th style={{textAlign: "right", paddingRight: "15px", fontSize: "0.9rem", fontWeight: 600}}>Rozdíl</th>
          <th style={{textAlign: "right", fontSize: "0.9rem", fontWeight: 600}}>CW Body</th>
          <th style={{paddingLeft: "15px", fontSize: "0.9rem", fontWeight: 600}}>Discord</th>
          {isUserLoggedIn() && <th style={{paddingLeft: "15px", fontSize: "0.9rem", fontWeight: 600}}>Akce</th>}
        </tr>
      </thead>
      <tbody>
      {rows.map((row, index) => (
          <tr key={index}>
            <td style={{fontSize: "0.85rem"}}>{row.nick} {renderRoleChip(row.role)}</td>
            <td>
              <Box sx={{ display: 'flex', gap: '3px' }}>
              {renderGamePasses(row.gamepasses, row.enchantSlots)}
              </Box>
            </td>
            <td style={{textAlign: "right", fontSize: "0.85rem"}}>{formatDate(row.date)}</td>
            <td style={{textAlign: "right", fontSize: "0.85rem"}}>{formatNumber(row.donate)}</td>
            <td style={{textAlign: "right", paddingRight: "15px", fontSize: "0.85rem"}}>{formatNumber(row.fee)}</td>
            <td style={{ ...getDiffCellStyle(row.donate - row.fee), textAlign: 'right', paddingRight: "15px", fontSize: "0.85rem" }}>{formatNumber(row.donate - row.fee)}</td>
            <td style={{...getDiffCellStyleCW(row.clanPoints), paddingRight: "15px", textAlign: "right", fontSize: "0.85rem"}}>{formatNumber(row.clanPoints, true)}</td>
            <td style={{paddingLeft: "15px", fontSize: "0.85rem"}}>{row.discord}</td>
            {isUserLoggedIn() && (
            <td style={{paddingLeft: "15px", fontSize: "1.4rem"}}>
            <button onClick={() => openModal(row.nick)} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
              <DeleteForeverIcon style={{ paddingTop: "2px", fontSize: 23 }}/>
            </button>
            </td>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
    </Sheet>
    </>
  );
}