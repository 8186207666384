import React, { useState } from 'react';
import { Button, Grid, Card, Input, Checkbox, Alert, Modal, ModalDialog } from '@mui/joy';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Config from '../Config';

function LoginForm({ open, onClose, onLoginSuccess }) {
    const [formData, setFormData] = useState({
        nick: '',
        password: '',
        rememberMe: false
    });
    const [errors, setErrors] = useState({});
    const [serverError, setServerError] = useState('');

    // Funkce pro změnu hodnot ve formuláři
    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleCheckboxChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.checked });
    };

    // Funkce pro validaci formuláře
    const validateForm = () => {
        let tempErrors = {};
        if (!formData.nick) tempErrors.nick = "Nick je povinný";
        if (!formData.password) tempErrors.password = "Heslo je povinné";
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const sendToServer = async (data) => {
        try {
            const response = await fetch(`${Config.domain}/api/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const result = await response.json();
            localStorage.setItem('token', result.token);
            onLoginSuccess(true);
            onClose()
            console.log(result); 
        } catch (e) {
            console.error("Chyba při odesílání dat: ", e);
            setServerError('Chyba při komunikaci se serverem.');
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (validateForm()) {
            sendToServer({
                nick: formData.nick,
                password: formData.password,
                rememberMe: formData.rememberMe
            });
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
        <ModalDialog sx={{ width: "500px", borderRadius: "12px", padding: "40px"}}>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={12} md={12}>
                            {serverError && <Alert severity="error">{serverError}</Alert>}
                            <form onSubmit={handleSubmit}>
                                <Input
                                    placeholder="Nick"
                                    variant="soft"
                                    name="nick"
                                    value={formData.nick}
                                    onChange={handleChange}
                                    error={!!errors.nick}
                                    helperText={errors.nick}
                                    style={{ marginBottom: "15px", borderRadius: "8px" }}
                                />
                                <Input
                                    type="password"
                                    placeholder="Heslo"
                                    variant="soft"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    error={!!errors.password}
                                    helperText={errors.password}
                                    style={{ marginBottom: "15px", borderRadius: "8px" }}
                                />
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                                    <Checkbox
                                        name="rememberMe"
                                        checked={formData.rememberMe}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label style={{ marginLeft: "10px" }} htmlFor="rememberMe">Zapamatovat přihlášení</label>
                                </div>
                                <Button endDecorator={<KeyboardArrowRight />} type="submit" style={{borderRadius: "8px"}} fullWidth>Přihlásit</Button>
                            </form>
                   
                    </Grid>
                </Grid>
            </ModalDialog>
            </Modal>
    );
}

export default LoginForm;
