import React, { useState } from 'react';
import { Button, Grid, Card, extendTheme, CssVarsProvider, Input, Alert, Checkbox, Typography, Sheet, FormLabel, Modal, ModalDialog } from '@mui/joy';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { FormControlLabel, FormGroup } from '@mui/material';
import Config from '../Config';

function RegisterForm({ open, onClose, onRegistrationSuccess }) {

    const [formData, setFormData] = useState({
        nick: '',
        discord: '',
        password: '',
        confirmPassword: '',
        isMember: false,
        enchantSlots: 0,
        playTime: 0,
        startFee: 0,
        note: "",
        gamepasses: {
            lucky: false,
            ultraLucky: false,
            hugeHunter: false,
            magicEggs: false,
            vip: false,
            autofarm: false,
            autotap: false,
            petEquip: false,
            moreEggs: false
        }
    });
    const [errors, setErrors] = useState({});
    const [serverError, setServerError] = useState('');
    const [validationError, setValidationError] = useState('');

    // Funkce pro změnu hodnot ve formuláři
    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const validateForm = () => {
        let tempErrors = {};
        let isValid = true;
        if (!formData.nick) {
            tempErrors.nick = "Nick je povinný!";
            isValid = false;
        }
        if (!formData.discord) {
            tempErrors.discord = "Discord je povinný!";
            isValid = false;
        }
        if (!formData.password) {
            tempErrors.password = "Heslo je povinné!";
            isValid = false;
        } else if (formData.password.length < 6) {
            tempErrors.password = "Heslo musí mít alespoň 6 znaků!";
            isValid = false;
        }
    
        if (formData.password !== formData.confirmPassword) {
            tempErrors.confirmPassword = "Hesla se neshodují!";
            isValid = false;
        }

        if (formData.enchantSlots < 0 || formData.enchantSlots > 3 || isNaN(formData.enchantSlots)) {
            tempErrors.enchantSlots = "Počet slotů musí být číslo od 0 do 3!";
            isValid = false;
        }

        if (formData.playTime < 1 || formData.playTime > 24 || isNaN(formData.playTime)) {
            tempErrors.playTime = "Hodnota musí být číslo od 1 do 24!";
            isValid = false;
        }

        if (!/^(0|(\d+(\.\d+)?[a-zA-Z]{1}))$/.test(formData.startFee.toString()) || formData.startFee.toString().replace(/[^a-zA-Z]/g, "").length > 1) {
            tempErrors.startFee = 'Zadaná hodnota musí být ve formátu např. "100k", "1M", 1.2M...';
            isValid = false;
        }
        
        
        setErrors(tempErrors);

        if (!isValid) {
            // Nastavení první chyby do stavu validationError
            const firstError = Object.values(tempErrors).find(Boolean);
            setValidationError(firstError);
        } else {
            setValidationError(''); // Odstranit chybovou zprávu, pokud nejsou žádné chyby
        }

        return isValid;
    };


    const sendToServer = async (data) => {
        try {
            const response = await fetch(`${Config.domain}/api/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    nick: formData.nick,
                    discord: formData.discord,
                    password: formData.password,
                    enchantSlots: formData.enchantSlots,
                    playTime: formData.playTime,
                    startFee: formData.startFee,
                    gamepasses: formData.gamepasses,
                    isMember: formData.isMember,
                    note: formData.note
                })
            });
            const result = await response.json();
            if (!response.ok) {
                throw new Error(result.message || `HTTP error! status: ${response.status}`);
            }
            onRegistrationSuccess(true);
            onClose()
            console.log(result);
        } catch (e) {
            console.error("Chyba při odesílání dat: ", e.message);
            setServerError(e.message); 
        }
    };
    

    const handleSubmit = (event) => {
        event.preventDefault();
        if (validateForm()) {
            sendToServer({
                nick: formData.nick,
                discord: formData.discord,
                password: formData.password,
                enchantSlots: formData.enchantSlots,
                playTime: formData.playTime,
                startFee: formData.startFee,
                gamepasses: formData.gamepasses,
                isMember: formData.isMember
            });
        }
    };

    const handleCheckboxChange = (event) => {
        if(event.target.name === 'isMember') {
            setFormData({ ...formData, isMember: event.target.checked });
        } else {
            setFormData({
                ...formData,
                gamepasses: {
                    ...formData.gamepasses,
                    [event.target.name]: event.target.checked
                }
            });
        }
    };

    const formatLabel = (label) => {
        return label
            .replace(/([A-Z])/g, ' $1')
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };


    const theme = extendTheme({ cssVarPrefix: 'demo' });
    return (
        <Modal open={open} onClose={onClose}>
        <ModalDialog sx={{ width: "600px", borderRadius: "12px", padding: "40px",
        '@media (max-width:600px)': { 
            height: "max-content",
            overflow: 'auto',
        }
    }}>
            <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '85vh',
             '@media (max-width:600px)': { 
                minHeight: "100vh"
            }
        }}>
                <Grid item xs={12} sm={12} md={12}>
                    {(validationError || serverError) && <Alert color="danger" variant="solid" severity="error" style={{marginBottom: "15px", border: "1px solid red", borderRadius: "8px"}}><strong>{validationError || serverError}</strong></Alert>}
                        <form onSubmit={handleSubmit}>
                        <FormLabel style={{ paddingBottom: "5px", fontSize: "0.85rem" }}>Roblox nick:</FormLabel>
                            <Input
                                placeholder="Roblox nick"
                                variant="soft"
                                name="nick"
                                value={formData.nick}
                                onChange={handleChange}
                                error={!!errors.nick}
                                helperText={errors.nick}
                                style={{marginBottom: "15px", borderRadius: "8px"}}
                            />
                            <FormLabel style={{ paddingBottom: "5px", fontSize: "0.85rem" }}>Discord:</FormLabel>
                            <Input
                                placeholder="Discord"
                                variant="soft"
                                name="discord"
                                value={formData.discord}
                                onChange={handleChange}
                                error={!!errors.discord}
                                helperText={errors.discord}
                                style={{marginBottom: "15px", borderRadius: "8px"}}
                            />
                            <FormLabel style={{ paddingBottom: "5px", fontSize: "0.85rem" }}>Ihned po přijetí vložím do clanu (např. 100k, 2m, 1.2m..):</FormLabel>
                            <Input
                                placeholder="např. 100k, 2m, 4.5m"
                                variant="soft"
                                name="startFee"
                                value={formData.startFee}
                                onChange={handleChange}
                                error={!!errors.startFee}
                                helperText={errors.startFee}
                                style={{marginBottom: "15px", borderRadius: "8px"}}
                            />
                            <FormLabel style={{ paddingBottom: "5px", fontSize: "0.85rem" }}>Kolik hodin denně máte spuštěný roblox (průměrně):</FormLabel>
                            <Input
                                placeholder="Např. 11, 15, 20"
                                variant="soft"
                                name="playTime"
                                type="number"
                                value={formData.playTime}
                                onChange={handleChange}
                                error={!!errors.playTime}
                                helperText={errors.playTime}
                                style={{marginBottom: "15px", borderRadius: "8px"}}
                            />
                            <FormLabel style={{ paddingBottom: "5px", fontSize: "0.85rem" }}>Vytvořit heslo:</FormLabel>
                            <Input
                                type="password"
                                placeholder="Vytvořit heslo"
                                variant="soft"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                error={!!errors.password}
                                helperText={errors.password}
                                style={{marginBottom: "15px", borderRadius: "8px"}}
                            />
                            <FormLabel style={{ paddingBottom: "5px", fontSize: "0.85rem" }}>Znovu heslo:</FormLabel>
                            <Input
                                type="password"
                                placeholder="Potvrdit heslo"
                                variant="soft"
                                name="confirmPassword"
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                error={!!errors.confirmPassword}
                                helperText={errors.confirmPassword}
                                style={{marginBottom: "15px", borderRadius: "8px"}}
                            />
                             <FormLabel style={{ paddingBottom: "5px", fontSize: "0.85rem" }}>Poznámka:</FormLabel>
                            <Input
                                placeholder="Poznámka"
                                variant="soft"
                                name="note"
                                value={formData.note}
                                onChange={handleChange}
                                fullWidth
                                style={{ marginBottom: "15px", borderRadius: "8px" }}
                            />
                    <div style={{marginBottom: "15px"}}>
                            <Typography component="h6" style={{fontWeight: 'bold', marginBottom: "15px"}}>Moje gamepassy</Typography>
                            <FormLabel style={{ paddingBottom: "5px", fontSize: "0.85rem" }}>Počet bonus enchant slotů (max. 3):</FormLabel>
                            <Input
                                type="number"
                                placeholder="Počet bonus enchant slotů (max. 3)"
                                variant="soft"
                                name="enchantSlots"
                                value={formData.enchantSlots}
                                onChange={handleChange}
                                error={!!errors.enchantSlots}
                                helperText={errors.enchantSlots}
                                style={{marginBottom: "15px", borderRadius: "8px"}}
                            />
                            <Sheet
                                sx={{
                                bgcolor: 'background.level1',
                                borderRadius: '8px',
                                p: 1.5,
                                my: 1.5,
                                display: 'flex',
                                gap: 2,
                                '& > div': { flex: 1 },
                                }}
                            > 
                           <FormGroup>
                                <Grid container spacing={0}>
                                    {Object.keys(formData.gamepasses).map((key, index) => (
                                        <Grid item xs={4} key={key}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name={key}
                                                        checked={formData.gamepasses[key]}
                                                        onChange={handleCheckboxChange}
                                                        style={{marginRight: "8px", marginLeft: "12px"}}
                                                    />
                                                }
                                                label={formatLabel(key)}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </FormGroup>
                            </Sheet>
                           </div>
                            <Button endDecorator={<KeyboardArrowRight />} type="submit" style={{borderRadius: "8px", marginTop: "15px"}} fullWidth>Registrovat</Button>
                        </form>
                  
                </Grid>
            </Grid>
            </ModalDialog>
    </Modal>
    );
}

export default RegisterForm;
