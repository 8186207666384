import React, { useEffect, useState } from 'react';
import { AspectRatio, Grid, Alert, Box, Button, Card, CardContent, Typography, Sheet, Modal, ModalDialog, Stack, FormLabel, Input, DialogTitle, DialogContent } from '@mui/joy';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import SettingModal from "./SettingModal"
import RegisterForm from "./RegisterForm"
import LoginForm from "./LoginForm"
import PendingActionsRoundedIcon from '@mui/icons-material/PendingActionsRounded';
import AssignmentIndRoundedIcon from '@mui/icons-material/AssignmentIndRounded';
import Config from '../Config';

export default function ClanProfile() {
  const [isDonateModalOpen, setIsDonateModalOpen] = useState(false);
  const [isSettingModalOpen, setIsSettingModalOpen] = useState(false);
  const [modalDonateData, setDonateModalDonateData] = useState({});
  const [userData, setUserData] = useState({});
  const [settingsData, setSettingsData] = useState({});
  const [totalMembers, setTotalMembers] = useState();
  const [serverMessage, setServerMessage] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${Config.domain}/api/clan/info`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setUserData(data.user);
      setSettingsData(data.settings);
      setTotalMembers(data.totalMembers);
      setUserRole(data.user.role);
      console.log(data)
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchClanData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${Config.domain}/api/clan/basicinfo`);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setSettingsData(data.settings);
      setTotalMembers(data.totalMembers);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchClanData();
  }, []);

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${Config.domain}/api/donate/add`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ donate: modalDonateData.donate })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // Zde můžeš přidat další logiku po úspěšném odeslání dat
      await fetchData();
      const data = await response.json();
      setServerMessage(data.message);
    } catch (error) {
      console.error('There was a problem with sending the data:', error);
    }

    setIsDonateModalOpen(false);
  };

  const handleSave = async (updatedData) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${Config.domain}/api/settings`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(updatedData)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      await fetchData()
      const data = await response.json();
      setServerMessage(data.message);
      setIsSettingModalOpen(false)
    } catch (error) {
      console.error('There was a problem with sending the data:', error);
    }
  };

  function formatNumber(num) {
    const sign = num < 0 ? '-' : '';
    const absNum = Math.abs(num);

    if (absNum < 1000) return sign + absNum; //Stovky
    if (absNum < 1000000) return sign + Math.round(absNum / 1000) + 'k'; // Tisíce

    const formatWithOptionalDecimal = (value, unit) => {
      const number = Math.floor(value) === value ? Math.floor(value) : value.toFixed(1);
      return sign + number + unit;
    };

    if (absNum < 1000000000) return formatWithOptionalDecimal(absNum / 1000000, 'M'); // Miliony
    if (absNum < 1000000000000) return formatWithOptionalDecimal(absNum / 1000000000, 'B'); // Miliardy
    return formatWithOptionalDecimal(absNum / 1000000000000, 'T'); // Triliony
  }

  const handleSettingButton = async () => {
    await fetchData()
    setIsSettingModalOpen(true)
  }

  const renderButtons = () => {
    const token = localStorage.getItem('token');

    if (!token) {
      return (
        <>
          <Button 
            variant="outlined"
            color="neutral"
            style={{ borderRadius: "7px", fontWeight: 600 }}
            onClick={() => setIsLoginModalOpen(true)}
          >
              Přihlásit se
          </Button>
          <Button
            endDecorator={<AssignmentIndRoundedIcon style={{ fontSize: 20 }} />}
            color="primary"
            style={{ borderRadius: "8px", fontWeight: 600 }}
            onClick={() => setIsRegisterModalOpen(true)}
          >
            Přihláška do clanu
          </Button>
        </>
      );
    } else if (userRole === 'pending') {
      return (
        <>
          <Button
            variant="outlined"
            color="neutral"
            style={{ borderRadius: "7px" }}
            onClick={() => handleSettingButton()}
          >
            Moje nastavení
          </Button>
          <Button
            endDecorator={<PendingActionsRoundedIcon style={{ fontSize: 18 }} />}
            color="primary"
            style={{ borderRadius: "8px", fontWeight: 600, backgroundColor: "#0a2744", color: "white" }}
            disabled
          >
            Žádost čeká na vyřízení
          </Button>
        </>
      )
    } else {
      return (
        <>
          <Button
            variant="outlined"
            color="neutral"
            style={{ borderRadius: "7px" }}
            onClick={() => handleSettingButton()}
          >
            Moje nastavení
          </Button>
          <Button
            style={{ borderRadius: "8px", fontWeight: 600 }}
            endDecorator={<VolunteerActivismIcon style={{ fontSize: 18 }} />}
            onClick={() => setIsDonateModalOpen(true)}
            disabled
          >
            Přidat příspěvek
          </Button>
        </>
      )
    }
  }

  const handleRegistrationSuccess = (success) => {
    setRegistrationSuccess(success);
    setTimeout(() => setRegistrationSuccess(false), 5000);
};

  const handleLoginSuccess = (success) => {
    setLoginSuccess(success);
    setTimeout(() => setLoginSuccess(false), 5000);
  };

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ width: '100%' }}>
    <Grid item xs={12} sm={12} md={12}>
    <Box
      sx={{
        width: '100%',
        position: 'relative',
        overflow: { xs: 'auto', sm: 'initial' },
      }}
    >
      {userData && userData.gamepasses && (
        <SettingModal
          open={isSettingModalOpen}
          onClose={() => setIsSettingModalOpen(false)}
          userData={userData}
          onSave={(updatedData) => handleSave(updatedData)}
        />
      )}
      {isRegisterModalOpen && (
      <RegisterForm
      open={isRegisterModalOpen}
      onClose={() => setIsRegisterModalOpen(false)}
      onRegistrationSuccess={handleRegistrationSuccess}
    />
      )}
           {isLoginModalOpen && (
      <LoginForm
      open={isLoginModalOpen}
      onClose={() => setIsLoginModalOpen(false)}
      onLoginSuccess={handleLoginSuccess}
    />
      )}
      {isDonateModalOpen && (
        <Modal open={isDonateModalOpen} onClose={() => setIsDonateModalOpen(false)}>
          <ModalDialog style={{ width: "500px", borderRadius: "12px", padding: "40px" }}>
            <DialogTitle>Přidat příspěvek</DialogTitle>
            <DialogContent>Zadaná hodnota se přičte k vašemu stávajícímu příspěvku.</DialogContent>
            <Stack flexGrow={1} spacing={1}>
              <FormLabel style={{ paddingLeft: "2px" }}>Přidat příspěvek (100k, 1M, 3.2M atd.):</FormLabel>
              <Input
                style={{ borderRadius: "8px" }}
                variant="soft"
                placeholder='200k'
                value={modalDonateData.donate}
                onChange={(e) => setDonateModalDonateData({ ...modalDonateData, donate: e.target.value })}
              />
            </Stack>
            <Button style={{ borderRadius: "7px" }} onClick={handleSubmit}>Potvrdit</Button>
          </ModalDialog>
        </Modal>
      )}
      {serverMessage && <Alert color="success" style={{ borderRadius: "7px", width: "96%", fontWeight: 600, marginBottom: "15px" }}>{serverMessage}</Alert>}
      {registrationSuccess && (
          <Alert color="success" style={{ borderRadius: "7px", width: "96%", fontWeight: 600, marginBottom: "15px" }}>Přihláška byla úspěšně odeslaná, nyní se můžeš přihlásit!</Alert>
      )}
      {loginSuccess && (
          <Alert color="success" style={{ borderRadius: "7px", width: "96%", fontWeight: 600, marginBottom: "15px" }}>Úspěšně přihlášen</Alert>
      )}
      <Card
        orientation="horizontal"
        sx={{
          width: '96%',
          flexWrap: 'wrap',
          overflow: 'auto',
          backgroundColor: "black"
        }}
      >
        <AspectRatio flex ratio="1" maxHeight={182} sx={{ minWidth: 182 }}>
          <img
            style={{ borderRadius: "8px" }}
            src="https://i.ibb.co/GnqTYb5/tno-logo-profile.png"
            srcSet="https://i.ibb.co/GnqTYb5/tno-logo-profile.png 2x"
            loading="lazy"
            alt=""
          />
        </AspectRatio>
        <CardContent>
          <Typography fontSize="1.6rem" fontWeight="700">
            [tNo] - Vidláci od tNo
          </Typography>
          <Typography level="body-sm" fontSize="1.2rem" fontWeight="lg" textColor="text.tertiary">
            <strong>Denní poplatek:</strong> {formatNumber(settingsData.dailyFee)}
          </Typography>
          <Sheet
            sx={{
              bgcolor: 'background.level1',
              borderRadius: '8px',
              p: 1.5,
              my: 1.5,
              display: 'flex',
              gap: 2,
              '& > div': { flex: 1 },
            }}
          >
            <div>
              <Typography level="body-xs" fontWeight="lg" fontSize="0.9rem">
                Clan level
              </Typography>
              <Typography fontWeight="lg">5</Typography>
            </div>
            <div>
              <Typography level="body-xs" fontWeight="lg" fontSize="0.9rem">
                Počet členů
              </Typography>
              <Typography fontWeight="lg">{totalMembers}</Typography>
            </div>
            <div>
              <Typography level="body-xs" fontWeight="lg" fontSize="0.9rem">
                Clan gems
              </Typography>
              <Typography fontWeight="lg">{formatNumber(settingsData.clanGems)}</Typography>
            </div>
            <div>
              <Typography level="body-xs" fontWeight="lg" fontSize="0.9rem">
                Můj příspěvek
              </Typography>
              <Typography fontWeight="lg">{formatNumber(userData.donate)}</Typography>
            </div>
          </Sheet>
          <Box sx={{ display: 'flex', gap: 1.5, '& > button': { flex: 1 } }}>
            {renderButtons()}
          </Box>
        </CardContent>
      </Card>
    </Box>
    </Grid>
    </Grid>
  );
}
