import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { Tabs } from '@mui/base/Tabs';
import { TabsList as BaseTabsList } from '@mui/base/TabsList';
import { TabPanel as BaseTabPanel } from '@mui/base/TabPanel';
import { buttonClasses } from '@mui/base/Button';
import { Tab as BaseTab, tabClasses } from '@mui/base/Tab';
import { Grid } from '@mui/joy';
import MyTable from "./MyTable"
import ClanProfile from "./ClanProfile"

export default function Root() {

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ width: '100%' }}>
    <Grid item xs={12} sm={12} md={12}>
    <div style={{marginLeft: "15%", marginRight: "15%"}}>
    <Tabs defaultValue={0}>
      <TabsList>
          <Tab value={0}>Clan</Tab>
          <Tab value={1}>Členové</Tab>
          <Tab value={2} disabled>Admin nastavení</Tab>
          <Tab value={3} disabled>Přihlášky</Tab>
          <Tab value={4} disabled>Správa členů</Tab>
          <Tab value={5} disabled>Private server</Tab>
        </TabsList>
      <TabPanel value={0}><ClanProfile/></TabPanel>
      <TabPanel value={1}><MyTable/></TabPanel>
      <TabPanel value={2}></TabPanel>
      <TabPanel value={3}></TabPanel>
      <TabPanel value={4}></TabPanel>
      <TabPanel value={5}></TabPanel>
    </Tabs>
    </div>
    </Grid>
    </Grid>
  );
}

const blue = {
  50: '#0B0D0E',
  100: '#0B0D0E',
  200: '#0B0D0E',
  300: '#0B0D0E',
  400: '#0B0D0E',
  500: '#0B0D0E',
  600: '#0B0D0E',
  700: '#0B0D0E',
  800: '#0B0D0E',
  900: '#0B0D0E',
};

const grey = {
  50: '#090b0e',
  100: '#090b0e',
  200: '#090b0e',
  300: '#090b0e',
  400: '#090b0e',
  500: '#090b0e',
  600: '#090b0e',
  700: '#434D5B',
  800: '#303740',
  900: '#090b0e',
};

const Tab = styled(BaseTab)`
  font-family: 'IBM Plex Sans', sans-serif;
  color: #fff;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: transparent;
  width: 100%;
  padding: 10px 12px;
  margin: 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${blue[400]};
  }

  &:focus {
    color: #fff;
    outline: 3px solid ${blue[200]};
  }

  &.${tabClasses.selected} {
    background-color: #fff;
    color: ${blue[600]};
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(BaseTabPanel)(
  ({ theme }) => `
  width: 98%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  padding: 20px 12px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  border-radius: 12px;
  opacity: 0.6;
  `,
);

const TabsList = styled(BaseTabsList)(
  ({ theme }) => `
  min-width: 100%;
  background-color: ${blue[500]};
  border-radius: 12px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  box-shadow: 0px 4px 30px ${theme.palette.mode === 'dark' ? grey[900] : grey[200]};
  `,
);