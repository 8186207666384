import * as React from 'react';
import { Box, Typography, Button } from '@mui/joy';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import Config from '../Config';


const PrivateServer = () => {

    const ConnectPrivate = () => {
          const url = `${Config.private}`; 
          window.open(url, '_blank');
        };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <img style={{ borderRadius: "8px", marginRight: "20px", width: "80%"}} src="https://tr.rbxcdn.com/b4f3eb329b52b3eb90185b651867471b/768/432/Image/Png"/>
      <Box>
        <Typography level="h3" sx={{ mb: 2 }}>Pravidla private serveru:</Typography>
          <p style={{color: "white"}}>➤ Server je vyhrazen pro všechny členy tNo, šíření odkazu mimo clan není povoleno!</p>
          <p style={{color: "white"}}>➤ V poslední lokaci je povoleno používat pouze Magnet Flag, žádná jinou vlajku v poslední lokaci nchceme vidět. Všechny ostatní předměty jako jary a comety si házejte dle libosti.</p>
          <p style={{color: "white"}}>➤ Buďte ohleduplní k ostatním a nelogujte zbytečně na server 5 altů, ať je prostor i pro ostatní hráče.</p>
          <p style={{color: "white"}}>➤ Když je tNo na serveru neskákejte mu po hlavě a nechovejte se jako 13 leté holky, když vidí svého idola..</p>
          <p style={{color: "white"}}>➤ Pokud nejste AFK a stojíte v poslední lokaci, pokládejte prosím Magnet Flagy, získáte za to malé, ale naprosto bezvýznamné plus!</p>
          <p style={{color: "white"}}>➤ Pokud se server bude sekat nebo se v poslední lokaci přestanou spawnovat objekty majitel serveru nenese odpovědnost za to že vás to odpojí kvůli restartu!</p>
        <Box sx={{ display: 'flex', gap: 1.5, '& > button': { flex: 1 } }}>
            <Button
              fullWidth
              style={{borderRadius: "8px", fontWeight: 600}}
              color="success"
              onClick={ConnectPrivate}
              startDecorator={<PlayArrowRoundedIcon style={{fontSize: "30px"}}/>}
            >
              Připojit na server
              </Button>
          </Box>
      </Box>
    </Box>
  );
};

export default PrivateServer;
