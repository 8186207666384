import React, { useEffect, useState } from 'react';
import { Box, Card, Typography, Input, Button, FormLabel, Stack, Alert } from '@mui/joy/';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import SavingsIcon from '@mui/icons-material/Savings';
import WebhookIcon from '@mui/icons-material/Webhook';
import Config from '../Config';

export default function AdminSettings() {
    const [settingsData, setSettings] = useState({
        dailyFee: '',
        clanGems: '',
        discordWebhook: ''
    });

    const [alert, setAlert] = useState({
        color: '', 
        message: '',
        show: false,
    });
    const [memberCount, setMemberCount] = useState(0);
    

    const fetchSettings = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${Config.domain}/api/settings/data`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            if (data.length > 0) {
                setSettings(data[0]);
            }
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    const fetchMemberCount = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${Config.domain}/api/members`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const membersData = await response.json();
            setMemberCount(membersData.length);

        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    useEffect(() => {
        fetchSettings();
        fetchMemberCount();
    }, []);

    const calculateIncome = (days) => {
        let convertNum;
      if (/^\d+[kmibt]$/i.test(settingsData.dailyFee)) {
        convertNum = parseNumber(settingsData.dailyFee);
      } else {
        convertNum = parseInt(settingsData.dailyFee);
      }
        return formatNumber(memberCount * convertNum * days);
    }

    const calculateClanGems = (days) => {
        let convertDailyFee;
        if (/^\d+[kmibt]$/i.test(settingsData.dailyFee)) {
            convertDailyFee = parseNumber(settingsData.dailyFee);
        } else {
            convertDailyFee = parseInt(settingsData.dailyFee);
        }
    
        let convertClanGems;
        if (/^\d+[kmibt]$/i.test(settingsData.clanGems)) {
            convertClanGems = parseNumber(settingsData.clanGems);
        } else {
            convertClanGems = parseInt(settingsData.clanGems);
        }
    
        return formatNumber(memberCount * convertDailyFee * days + convertClanGems);
    }
    

    const handleUpdateSetting = async (key, value) => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${Config.domain}/api/settings/update`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ [key]: value }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            setAlert({ color: 'success', message: 'Úspěšně aktualizováno!', show: true });
        } catch (error) {
            console.error('There was a problem with the update operation:', error);
            setAlert({ color: 'error', message: 'Chyba při aktualizaci!', show: true });
        }
    };

    function formatNumber(num) {
        const sign = num < 0 ? '-' : '';
        const absNum = Math.abs(num);
    
        if (absNum < 1000) return sign + absNum; //Stovky
        if (absNum < 1000000) return sign + Math.round(absNum / 1000) + 'k'; // Tisíce
    
        const formatWithOptionalDecimal = (value, unit) => {
            const number = Math.floor(value) === value ? Math.floor(value) : value.toFixed(1);
            return sign + number + unit;
        };
    
        if (absNum < 1000000000) return formatWithOptionalDecimal(absNum / 1000000, 'M'); // Miliony
        if (absNum < 1000000000000) return formatWithOptionalDecimal(absNum / 1000000000, 'B'); // Miliardy
        return formatWithOptionalDecimal(absNum / 1000000000000, 'T'); // Triliony
    }

    function parseNumber(str) {
        const units = {
            k: 1000,
            m: 1000000,
            b: 1000000000,
            t: 1000000000000
        };
        const unit = str.toLowerCase().match(/[kmibt]+$/); 
        const number = parseFloat(str, 10);
    
        if (unit && units[unit[0]]) {
            return number * units[unit[0]];
        }
        return number;
    }
    
    return (
        <Box
            sx={{
                width: '100%',
                position: 'relative',
                overflow: { xs: 'auto', sm: 'initial' },
            }}
        >
            <Typography component="h1" fontSize="1.3rem" style={{paddingLeft: "4px", paddingBottom: "15px"}}>Admin nastavení</Typography>
            {alert.show && (
            <Alert sx={{width: "97%", marginBottom: "15px", borderRadius: "8px"}} color={alert.color} onClose={() => setAlert({ ...alert, show: false })}>
                {alert.message}
            </Alert>
        )}
            <Card
                orientation="horizontal"
                sx={{
                    width: '97%',
                    flexWrap: 'wrap',
                    overflow: 'auto',
                    backgroundColor: "black"
                }}
            >
                <Stack direction="row" spacing={2} sx={{ width: '100%', padding: 2 }}>
                 <Stack flexGrow={1} spacing={1}>
                <FormLabel style={{paddingLeft: "2px"}}>Nastavit denní poplatek:</FormLabel>
                <Input
                    fullWidth
                    size="xl"
                    style={{borderRadius: "6px"}}
                    startDecorator={<PriceChangeIcon style={{ fontSize: 30, paddingLeft: 8 }} />}
                    endDecorator={<Button onClick={() => handleUpdateSetting('dailyFee', settingsData.dailyFee)}>Nastavit</Button>}
                    placeholder={formatNumber(settingsData.dailyFee)}
                    onChange={(e) => setSettings({...settingsData, dailyFee: e.target.value})}
                />
                </Stack>
                <Stack flexGrow={1} spacing={1}>
                <FormLabel style={{paddingLeft: "2px"}}>Clan gems:</FormLabel>
                <Input
                    fullWidth
                    size="xl"
                    style={{borderRadius: "6px"}}
                    startDecorator={<SavingsIcon style={{ fontSize: 30, paddingLeft: 8 }} />}
                    endDecorator={<Button onClick={() => handleUpdateSetting('clanGems', settingsData.clanGems)}>Nastavit</Button>}
                    placeholder={formatNumber(settingsData.clanGems)}
                    onChange={(e) => setSettings({...settingsData, clanGems: e.target.value})}
                />
                </Stack>
                <Stack flexGrow={1} spacing={1}>
                <FormLabel style={{paddingLeft: "2px"}}>Discord webhook:</FormLabel>
                <Input
                    fullWidth
                    size="xl"
                    style={{borderRadius: "6px"}}
                    startDecorator={<WebhookIcon style={{ fontSize: 30, paddingLeft: 8 }} />}
                    endDecorator={<Button onClick={() => handleUpdateSetting('webhook', settingsData.webhook)}>Nastavit</Button>}
                    placeholder={settingsData.webhook}
                    onChange={(e) => setSettings({...settingsData, webhook: e.target.value})}
                />
                </Stack>
                </Stack>
                <Stack spacing={0} style={{paddingLeft: "16px"}}>
                    <Typography fontSize="0.9rem"><strong>Předpokládaný příjem za 1 den:</strong> {calculateIncome(1)} (celkový odhad gemů: {calculateClanGems(1)})</Typography>
                    <Typography fontSize="0.9rem"><strong>Předpokládaný příjem za 7 dní:</strong> {calculateIncome(7)} (celkový odhad gemů: {calculateClanGems(7)})</Typography>
                    <Typography fontSize="0.9rem"><strong>Předpokládaný příjem za 30 dní:</strong> {calculateIncome(30)} (celkový odhad gemů: {calculateClanGems(30)})</Typography>
        </Stack>
            </Card>
        </Box>
    )
}