import React from 'react';
import { Sheet, Grid, Modal, ModalDialog, Input, Button, Checkbox, Typography, FormLabel, Alert } from '@mui/joy';
import { FormControlLabel, FormGroup } from '@mui/material';

export default function SettingModal({ open, onClose, userData, onSave }) {
    const [formData, setFormData] = React.useState({ ...userData });
    const [errors, setErrors] = React.useState({});

    const handleInputChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleCheckboxChange = (event) => {
        setFormData({
            ...formData,
            gamepasses: {
                ...formData.gamepasses,
                [event.target.name]: event.target.checked
            }
        });
    };

    const validateDonate = (value) => {
        const regex = /^(0|(\d+(\.\d+)?[a-zA-Z]{1}))$/;
        return regex.test(value);
      };

    const validateForm = () => {
        let tempErrors = {};
        let isValid = true;
    
        // Nick Validation
        if (!formData.nick) {
          tempErrors.nick = "Roblox nick je povinný!";
          isValid = false;
        }
    
        // Discord Validation
        if (!formData.discord) {
          tempErrors.discord = "Discord je povinný!";
          isValid = false;
        }
    
        // Enchant Slots Validation
        if (formData.enchantSlots < 0 || formData.enchantSlots > 3 || isNaN(formData.enchantSlots)) {
          tempErrors.enchantSlots = "Počet slotů musí být číslo od 0 do 3!";
          isValid = false;
        }
    
        if (!validateDonate(formData.donate)) {
            tempErrors.donate = "Povolený formát: 1m, 100k, 1.2m atd.";
            isValid = false;
          }
    
        setErrors(tempErrors);
        return isValid;
      };

      const handleSubmit = () => {
        if (validateForm()) {
          onSave(formData);
          onClose();
        }
      };

    const formatLabel = (label) => {
        return label
            .replace(/([A-Z])/g, ' $1')
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    function formatNumber(num) {
        const sign = num < 0 ? '-' : '';
        const absNum = Math.abs(num);
    
        if (absNum < 1000) return sign + absNum; //Stovky
        if (absNum < 1000000) return sign + Math.round(absNum / 1000) + 'k'; // Tisíce
    
        const formatWithOptionalDecimal = (value, unit) => {
            const number = Math.floor(value) === value ? Math.floor(value) : value.toFixed(1);
            return sign + number + unit;
        };
    
        if (absNum < 1000000000) return formatWithOptionalDecimal(absNum / 1000000, 'M'); // Miliony
        if (absNum < 1000000000000) return formatWithOptionalDecimal(absNum / 1000000000, 'B'); // Miliardy
        return formatWithOptionalDecimal(absNum / 1000000000000, 'T'); // Triliony
    }

    const logoutUser = () => {
        localStorage.removeItem('token');
        window.location.href = '/';
    };
    
  
    return (
        <Modal open={open} onClose={onClose}>
            <ModalDialog style={{ width: "650px", borderRadius: "12px", padding: "40px" }}>
                <Typography component="h4" style={{ fontWeight: 'bold', marginBottom: "15px" }}>Upravit Nastavení</Typography>
                {Object.keys(errors).map((key) => {
                    if (errors[key]) {
                        return (
                            <Alert color="danger" key={key} style={{ marginBottom: "5px", borderRadius: "7px" }}>
                                {errors[key]}
                            </Alert>
                        );
                    }
                    return null;
                })}
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormLabel style={{ paddingBottom: "3px", fontSize: "0.85rem" }}>Roblox nick:</FormLabel>
                        <Input
                            fullWidth
                            placeholder="Roblox nick"
                            variant="soft"
                            name="nick"
                            value={formData.nick}
                            onChange={handleInputChange}
                            style={{borderRadius: "7px"}}
                            error={!!errors.nick}
                            helperText={errors.nick}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormLabel style={{ paddingBottom: "3px", fontSize: "0.85rem" }}>Discord:</FormLabel>
                        <Input
                            fullWidth
                            placeholder="Discord"
                            variant="soft"
                            name="discord"
                            value={formData.discord}
                            onChange={handleInputChange}
                            style={{borderRadius: "7px"}}
                            error={!!errors.discord}
                            helperText={errors.discord}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormLabel style={{ paddingBottom: "3px", fontSize: "0.85rem" }}>Bonusové enchant sloty:</FormLabel>
                        <Input
                            fullWidth
                            type="number"
                            placeholder="Počet bonus enchant slotů (max. 3)"
                            variant="soft"
                            name="enchantSlots"
                            value={formData.enchantSlots}
                            onChange={handleInputChange}
                            style={{borderRadius: "7px"}}
                            error={!!errors.enchantSlots}
                            helperText={errors.enchantSlots}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormLabel style={{ paddingBottom: "3px", fontSize: "0.85rem" }}>Váš celkový příspěvek:</FormLabel>
                        <Input
                            fullWidth
                            variant="soft"
                            name="donate"
                            placeholder={formatNumber(formData.donate)}
                            onChange={handleInputChange}
                            style={{borderRadius: "7px"}}
                            error={!!errors.donate}
                            helperText={errors.donate}
                        />
                    </Grid>
                </Grid>
                <Typography style={{ fontWeight: 'bold' }}>Moje Gamepassy</Typography>
                <Sheet
                    sx={{
                        bgcolor: 'background.level1',
                        borderRadius: '8px',
                        p: 1.5,
                        my: 1.5,
                        display: 'flex',
                        gap: 2,
                        '& > div': { flex: 1 },
                    }}
                >
                    <FormGroup>
                        <Grid container spacing={0}>
                            {Object.keys(formData.gamepasses).map((key, index) => (
                                <Grid item xs={4} key={key}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name={key}
                                                checked={formData.gamepasses[key]}
                                                onChange={handleCheckboxChange}
                                                style={{ marginRight: "8px", marginLeft: "15px"}}
                                            />
                                        }
                                        label={formatLabel(key)}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </FormGroup>
                </Sheet>
                <Button style={{borderRadius: "7px"}} onClick={handleSubmit}>Uložit Změny</Button>
                <Button variant="solid" color="danger" style={{ borderRadius: "7px", marginTop: "5px" }} onClick={logoutUser}>Odhlásit se</Button>
            </ModalDialog>
        </Modal>
    );
}
